import { getSearchAppControllers } from '../searchAppController';
import { EditorAppContext } from '../types';
import { Interaction } from '../constants/interaction';
import {
  removeController,
  removeControllerConnectedComponents,
} from '../editorSDKHelpers';
import { searchSuggestionsAppDefId } from '../constants/appDefIds';
import { createSettingsClient } from '@wix/search-settings-client';
import { isSearchSuggestionsAppInstalled } from '../searchSuggestionsModal';

interface SuggestionAppPublicAPI {
  uninstallApp: () => Promise<void>;
}

export async function onRemoveApp(appContext: EditorAppContext) {
  const { fedops, reportError } = appContext;
  fedops.interactionStarted(Interaction.RemoveApp);

  const controllerRefs = await getSearchAppControllers(appContext);

  for (const controllerRef of controllerRefs) {
    try {
      await removeControllerConnectedComponents(appContext, controllerRef);
    } catch (error) {
      reportError(error);
    }

    try {
      await removeController(appContext, controllerRef);
    } catch (error) {
      reportError(error);
    }
  }

  removeSuggestionsAndSlots(appContext);

  await saveEditor(appContext);
  fedops.interactionEnded(Interaction.RemoveApp);
}

async function saveEditor(appContext: EditorAppContext) {
  const { editorSDK, reportError, appDefinitionId } = appContext;

  try {
    await editorSDK.editor.save(appDefinitionId);
  } catch (error) {
    reportError(error);
  }
}

async function uninstallSuggestionsApp(appContext: EditorAppContext) {
  const { editorSDK, reportError } = appContext;
  try {
    const suggestionAppApi: SuggestionAppPublicAPI =
      (await editorSDK.application.getPublicAPI('token', {
        appDefinitionId: searchSuggestionsAppDefId,
      })) as SuggestionAppPublicAPI;

    await suggestionAppApi.uninstallApp();
  } catch (error) {
    reportError(error);
  }
}

export const resetProductsSlotSettings = async (
  appContext: EditorAppContext,
) => {
  const { flowAPI, reportError } = appContext;
  const httpClient = flowAPI.httpClient;
  const settingsClient = createSettingsClient({
    httpClient,
  });
  try {
    await settingsClient.set('isProductSlotInstalled', false);
  } catch (error) {
    reportError(error);
  }
};

async function removeSuggestionsAndSlots(appContext: EditorAppContext) {
  const { editorSDK } = appContext;
  const isInstalled = await isSearchSuggestionsAppInstalled(editorSDK);

  if (isInstalled) {
    await Promise.all([
      uninstallSuggestionsApp(appContext),
      resetProductsSlotSettings(appContext),
    ]);
  }
}
