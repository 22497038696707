import type { FlowAPI, Experiments } from '@wix/yoshi-flow-editor';
import type { EditorSDK, Layout } from '@wix/editor-platform-sdk-types';

export enum ActionType {
  RemoveApp = 'removeApp',
}

export enum ComponentType {
  SearchBox = 'wixui.SearchBox',
  TPA = 'wysiwyg.viewer.components.tpapps.TPASection',
  AppWidget = 'platform.components.AppWidget',
}

export enum ControllerType {
  SearchApp = 'SearchAppController',
  SearchButton = 'SearchButtonController',
}

export enum WixCodeRole {
  SearchBox = 'searchBox',
  SearchButton = 'searchButton',
}

export type EditorAppContext = {
  flowAPI: FlowAPI;
  appDefinitionId: string;
  editorSDK: EditorSDK;
  experiments: Experiments;
  fedops: FlowAPI['fedops'];
  reportError(error: unknown): void;
  isEditorX: boolean;
  isClassicEditor: boolean;
  translate: FlowAPI['translations']['t'];
};

// TODO: Should we just use Layout['width'] and Layout['height']? Bounding property
// is not documented nor typed in Editor SDK.
export type ComponentLayout = Layout & {
  bounding: {
    width: number;
    height: number;
  };
};
