import { AppManifestBuilder } from '@wix/yoshi-flow-editor';
import { EditorAppContext } from '../types';
import { overrideSearchBoxGfpp } from '../overrideSearchBoxGfpp';
import { overrideSearchButtonGfpp } from '../overrideSearchButtonGfpp/overrideSearchButtonGfpp';

export const overrideSearchComponentsGfpp = (
  appContext: EditorAppContext,
  appManifestBuilder: AppManifestBuilder,
) => {
  const builder = overrideSearchBoxGfpp(appContext, appManifestBuilder);
  return overrideSearchButtonGfpp(appContext, builder);
};
