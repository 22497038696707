import { EditorAppContext } from '../types';
import {
  installSearchSuggestionsModal,
  isSearchSuggestionsAppInstalled,
} from '../searchSuggestionsModal';
import { SEARCH_RESULTS_PAGE_URL_SLUG } from '../constants';
import { getSearchResultsPage } from '../searchResults';
import { createSettingsClient } from '@wix/search-settings-client';

export const migrateToNewSearch = async (appContext: EditorAppContext) => {
  const { editorSDK, appDefinitionId, flowAPI } = appContext;
  const isInstalled = await isSearchSuggestionsAppInstalled(editorSDK);

  if (isInstalled) {
    return;
  }

  await installSearchSuggestionsModal(editorSDK);

  const searchResultsPageRef = await getSearchResultsPage(appContext);

  await editorSDK.pages.data.update(appDefinitionId, {
    pageRef: searchResultsPageRef,
    data: {
      pageUriSEO: SEARCH_RESULTS_PAGE_URL_SLUG,
      appDefinitionId,
    },
  });

  const httpClient = flowAPI.httpClient;
  const settingsClient = createSettingsClient({
    httpClient,
  });
  try {
    await settingsClient.set('isProductSlotInstalled', true);
  } catch (error) {
    reportError(error);
  }
};
