import { migrateToNewSearch } from './migrateToNewSearch/migrateToNewSearch';
import { installProductsWidget } from './installProductsWidget/installProductsWidget';
import { EditorAppContext } from './types';
import { Spec } from '@wix/site-search-common';

export const configurePagesTab = async (
  appContext: EditorAppContext,
  pagesTabBuilder: any,
) => {
  const useSearchSuggestionsModal = appContext.experiments.enabled(
    Spec.UpgradeToNewSearch,
  );
  const deleteAction = {
    title: appContext.translate(
      'Site_Search_Editor_PageTitle_DeleteWixSiteSearch',
    ),
    icon: 'deleteAction',
    onClick: async (event: any) => {
      await appContext.editorSDK.application.uninstall('token', {
        openConfirmation: true,
      });
    },
  };

  const updateAppAction = {
    title: appContext.translate('Update App'),
    icon: 'settings',
    onClick: async (event: any) => {
      await migrateToNewSearch(appContext);
      await installProductsWidget({
        editorSDK: appContext.editorSDK,
        appDefinitionId: appContext.appDefinitionId,
        flowAPI: appContext.flowAPI,
      });
    },
  };

  const tabActions = useSearchSuggestionsModal
    ? [deleteAction, updateAppAction]
    : [deleteAction];

  return pagesTabBuilder
    .set({
      displayName: appContext.translate('siteSearch.pages.app.displayName'),
    })
    .addAction(...tabActions);
};
